@import "variables";

/* Qui sommes nous */
.qui_nous {
    padding-top: 120px;
    padding-bottom: 50px;

    display: flex;
    flex-direction: column;

    background-color: #e9f7fe;
}

.qui_nous__description {
    display: flex;
    justify-content: space-between;

    margin-bottom: 100px;
}

.qui_nous__image {
    width: 475px;
    height: 653px;

    margin-right: 30px;

    img {
        height: inherit;
    }
}

.qui_nous__texts_container {
    display: flex;
    flex-direction: column;

    width: 475px;
    max-width: 100%;

    transition: height 0.35s ease-in-out;

    p {
        font-size: 18px;
        line-height: 32px;
        color: #7c8087;
        text-align: justify;
    }
}

.qui_nous__voir {
    display: flex;
    justify-content: flex-end;

    color: $primary;
    font-size: 16px;

    margin-top: 30px;
    margin-bottom: 30px;
}

.qui_nous__voir > * {
    cursor: pointer;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.qui_nous__nos_valeurs {
    display: grid;
    grid-auto-flow: column;

    overflow-x: hidden;

    scroll-behavior: smooth;

    margin-bottom: 35px;

    @media screen and (min-width: 900px) {
        &:before,
        &:after {
            content: "";
            width: 100px;
        }
    }
}

.qui_nous__buttons_container {
    align-self: center;

    width: 50%;

    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-content: center;
}

.qui_nous__buttons {
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    outline: none;
    border: none;

    width: 50px;
    height: 50px;
    padding: 15px;

    background-color: $primary;
    color: white;

    cursor: pointer;
}

.qui_nous__buttons svg {
    fill: white;
}

.qui_nous__buttons:disabled {
    background-color: transparent;
    border: 0.2px solid #22b0fc;
    cursor: initial;
}

.qui_nous__buttons:disabled svg {
    fill: $primary;
}

@media screen and (min-width: 2260px) {
    .qui_nous__buttons_container {
        display: none;
    }
}

.qui_nous__valeur {
    background: #ffffff;
    border-radius: 10px;

    width: 350px;

    height: 175px;

    margin: 0 15px;

    display: flex;
}

@media screen and (max-width: 900px) {
    .qui_nous__valeur {
        width: calc(100vw / 2 - 30px);
    }
}

@media screen and (max-width: 750px) {
    .qui_nous__valeur {
        width: calc(100vw - 30px);
    }
}

.qui_nous__valeur_icon {
    margin: 20px;
    margin-bottom: 0;

    height: 55px;
    width: 55px;

    display: flex;
    justify-content: center;
    align-items: center;

    border-radius: 50%;

    img {
        height: 26px;
        width: 27px;
        color: $primary;
    }
}

.qui_nous__valeur_text {
    margin-top: 16px;
    width: 233px;

    h3 {
        color: $accent;
        font-size: 21px;
        line-height: 34px;
    }

    p {
        font-size: 16px;
        line-height: 28px;
        color: #7c8087;
    }
}

.qui_nous__texts_navigation {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.carousel-indicators {
    z-index: 0 !important;
}

.carousel-indicators li {
    width: 40px !important;
    height: 6px !important;

    border: unset !important;
    border-radius: 2px;

    -moz-box-shadow: 5px 5px 10px 0px #656565;
    -webkit-box-shadow: 5px 5px 10px 0px #656565;
    -o-box-shadow: 5px 5px 10px 0px #656565;
    box-shadow: 1px 1px 2px 0px #1d293f;

    cursor: pointer;

    transition: scale 0.2s ease-in-out !important;

    background-color: #22b0fc !important;
}

.carousel-indicators li:hover {
    transform: scale(1.05);
}

.carousel-indicators li.active {
    background-color: $primary !important;
}

.carousel-indicators {
    position: relative;
    bottom: -50px !important;
}

@media screen and (max-width: 1100px) {
    .qui_nous__image {
        margin-bottom: 50px;
    }

    .qui_nous__description {
        align-items: flex-start;
        justify-content: center;
    }

    .qui_nous__texts_container {
        width: unset;
    }

    .qui_nous__image {
        height: 400px;

        img {
            border-radius: 25px;
        }
    }
}

@media screen and (max-width: 800px) {
    .qui_nous__image {
        height: 300px;
        margin: 25px 0;
    }

    .qui_nous__description {
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
    }
}

@media screen and (min-width: 801px) {
    .qui_nous__title {
        margin-bottom: 25px;
        margin-left: 25px;
    }
}
